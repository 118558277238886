
declare var $: any;

export function doAnimate() {

  $('.transition-overlay').removeClass("active");
  setTimeout(function () {
    $('.transition-overlay').toggleClass("active");
  }, 100);

  setTimeout(function () {
    $('.transition-overlay').removeClass("active");
    $(".hamburger").toggleClass("open");
    $("body").toggleClass("overflow");

    if ($(".hamburger").hasClass("open")) {
      $(".hamburger").toggleClass("open");
    }
    if ($("body").hasClass("overflow")) {
      $("body").toggleClass("overflow");
    }

    $(".navigation-menu").removeClass("active");
    $(".navigation-menu .inner ul").css("transition-delay", "0s");
    $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
    $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");
  }, 1000);
}
