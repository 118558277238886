import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/url.constants';

@Injectable({
  providedIn: 'root'
})

export class FacultyService {

  constructor(private http: HttpClient) { }
  
  ///** GET faculty from the server */
  GetFaculty(): Observable<any> {
    const httpBody = new HttpParams()
      .set('SeoName', 'Faculty');

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.TOURDETAIL_SERVER_URL, httpBody, options);
  }

  ///** GET faculty detail from the server */
  GetFacultyData(seoname): Observable<any> {
    const httpBody = new HttpParams()
      .set('SeoName', seoname);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.TOURDETAIL_SERVER_URL, httpBody, options);
  }
}
