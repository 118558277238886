import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TourDateService } from '../../services/tour-date.service';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { doAnimate } from 'src/app/shared/app.helpers';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-tourdetail-preevent',
  templateUrl: './tourdetail-preevent.component.html',
  styleUrls: ['./tourdetail-preevent.component.scss'],
  providers: [TourDateService],
})
export class TourdetailPreeventComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  constructor(private tourdateService: TourDateService, private http: Http, private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
  }

  eventseoname: any;
  eventdetaildata: any;
  preEventDetail = {
    generalScheduleUrl: null,
  };
  isSoldout: boolean = false;
  index: any = 0;
  nationalfinalslist: any = [];
  latitude: any;
  longitude: any;
  latlong: any = [];
  isLoading: boolean = false;
  isEventStarted: boolean = false;
  isEventRegisterEnded: boolean = false;
  isDirector: boolean = false;
  isEventEnd: boolean = false;
  isEventListingNotes: boolean = false;
  postEventDetail = {
    ResultUrl: ""
  };
  videourllist: any = [];
  virtualdocurl: any;
  Listingtext: any;
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.route.paramMap.subscribe(params => {
      this.eventseoname = params.get('eventseoname');
    });

    this.getEventdetail(this.eventseoname);
   /* this.getNationalFinalsList();*/
  }

  getEventdetail(eventseoname) {
    this.isLoading = true;
    this.tourdateService.GetEventDetail(eventseoname).subscribe(evntdetail => {
      this.isLoading = false;
      if (evntdetail != null) {
        this.eventdetaildata = evntdetail;
        this.isEventEnd = this.eventdetaildata.Response.Data.EventDetail.IsEventEnd;
        if (this.eventdetaildata.Response.Data.EventDetail.PreEventDetail != null && this.isEventEnd == false) {
          this.preEventDetail = this.eventdetaildata.Response.Data.EventDetail.PreEventDetail;
          if (this.eventdetaildata.Response.Data.EventDetail.PreEventDetail.ListingNotes != "") {
            this.isEventListingNotes = true;
            this.Listingtext = this.sanitizer.bypassSecurityTrustHtml(this.eventdetaildata.Response.Data.EventDetail.PreEventDetail.ListingNotes);
          }
          this.isSoldout = this.eventdetaildata.Response.Data.EventDetail.IsSoldout;
          this.isEventStarted = this.eventdetaildata.Response.Data.EventDetail.IsEventStarted;
          this.isEventRegisterEnded = this.eventdetaildata.Response.Data.EventDetail.IsEventRegisterEnded;
          this.nationalfinalslist = this.eventdetaildata.Response.Data.NationalImagelist;
          this.eventdetaildata.Response.Data.EventDetail.PreEventDetail.VenueList.forEach(childObj => {
            this.latlong = childObj.LatLong.split(",");
            this.latitude = parseFloat(this.latlong[0]);
            this.longitude = parseFloat(this.latlong[1]);
          })
          this.eventdetaildata.Response.Data.EventDetail.PreEventDetail.DirectorList.forEach(childObj => {
            if (childObj.Roles == 'Director') {
              this.isDirector = true;
            }
          })
        }
        else {
          this.postEventDetail = this.eventdetaildata.Response.Data.EventDetail.PostEventDetail;
          this.nationalfinalslist = this.eventdetaildata.Response.Data.NationalImagelist;
          this.eventdetaildata.Response.Data.EventDetail.PostEventDetail.VideoUrlList.forEach(childObj => {
            childObj = this.sanitizer.bypassSecurityTrustResourceUrl(childObj);
            this.videourllist.push(childObj);
          })
        }
      }
    }, err => {
      console.log('err in tour date detail - Pre event component', err);
      this.isLoading = false;
    });
  }

  //getNationalFinalsList() {
  //  this.tourdateService.GetNationalFinalsList().subscribe(Nationalfinals => {
  //    if (Nationalfinals != null) {
  //      this.nationalfinalslist = Nationalfinals.Response.Data;
  //    }
  //  }, err => {
  //    console.log('err in tour date detail - Pre event component', err);
  //  });
  //}

  onNavigate(type, data) {

    if (this.index > 0) {
      type = "Default";
    }

    if (data == null || data == '') {
      return;
    }

    if (type == "Detail") {
      this.router.navigate(['/faculty-detail', data]);
    }
    else if (type == "facebook") {
      this.index = this.index + 1;
      window.open(data);
    }
    else if (type == "instagram") {
      this.index = this.index + 1;
      window.open(data);
    }
    else if (type == "website") {
      this.index = this.index + 1;
      window.open(data);
    }
    else if (type == "Default") {
      this.index = 0;
    }
    else {
      console.log('err in tour date component - pre event page');
    }
  }

  onNavigateResultUrl() {
    window.open(this.postEventDetail.ResultUrl);
  }

  onbookonlineclick(bookonlinelink) {
    window.open(bookonlinelink);
  }

  gotoRought(_url, _isHref) {
    doAnimate();
    setTimeout(() => {
      if (_isHref)
        window.open(_url, "_self");
      else {
        this.router.navigate([_url]);
      }
    }, 500);
  }

  getEventDetails(_url) {
    if (_url.indexOf(location.origin) > -1) {
      _url = _url.replace(location.origin, "");
    }
    this.gotoRought(_url, false);
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  onNavigateVirtual() {
    this.virtualdocurl = "/assets/document/2020 _Virtual_DOTYEvent.pdf";
    window.open(this.virtualdocurl);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
