import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DownloadMediaService } from '../../services/download-media.service';
import { Http, Response } from "@angular/http";
import { Subscription } from 'rxjs';
import { Routes, RouterModule, Router } from '@angular/router';
import { Condition } from 'selenium-webdriver';

declare var $: any;

@Component({
  selector: 'app-download-media',
  templateUrl: './download-media.component.html',
  styleUrls: ['./download-media.component.scss'],
  providers: [DownloadMediaService],
})
export class DownloadMediaComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private downloadmediaService: DownloadMediaService, private http: Http, private router: Router) { }
  downloadmediadata: any;
  selectListItem: any = [];
  eventslist: any = [];
  dancerslist: any = [];
  entrieslist: any = [];
  dates: any;
  daterange: any = [];
  months: any;
  monthrange: any = [];
  years: any;
  yearrange: any = [];
  emailsbelongs: any;
  emailslist: any = [];
  season: any;
  selectedValue: string;
  birthdate: any;
  _Responsedata: any;
  date: any = "Select";
  month: any = "Select";
  year: any = "Select";
  hour: any;
  minute: any;
  second: any;
  SuccessMessage: any;
  ErrorMessage: any;
  DownloadMediaData = {
    SeasonId: 0,
    EventId: null,
    DancerName: "Select",
    Email: "",
    EmailBelongsTo: "",
    Type: "",
    StudioEventEntryId: null,
    BirthDate: ""
  };
  isLoading: boolean = false;
  selectedSeason: any;
  isVerified: boolean = true;
  Responsedata: any;

  ngOnInit() {
    this.geteventmediadata();
    this.getdate();
    this.getmonth();
    this.getYear();
    this.getemailbelongslist();
  }

  getdate() {
    //this.daterange.push({ 'text': 'Select', 'value': 0 });
    for (var i = 1; i <= 31; i++) {
      if (i <= 9) {
        this.daterange.push({ 'text': '0' + "" + i, 'value': i });
      }
      else {
        this.daterange.push({ 'text': i, 'value': i });
      }
    }
    this.dates = this.daterange;
  }

  getmonth() {
    //this.monthrange.push({ 'text': 'Select', 'value': 0 });
    for (var i = 1; i <= 12; i++) {
      if (i <= 9) {
        this.monthrange.push({ 'text': '0' + "" + i, 'value': i });
      }
      else {
        this.monthrange.push({ 'text': i, 'value': i });
      }
    }
    this.months = this.monthrange;
  }

  getYear() {
    let currentyear = new Date().getFullYear();
    //this.yearrange.push({ 'text': 'Select', 'value': 0 });
    this.yearrange.push({ 'text': currentyear, 'value': 1 });

    for (var i = 1; i < 120; i++) {
      this.yearrange.push({ 'text': currentyear - i, 'value': i + 1 });
    }
    this.years = this.yearrange;
  }

  getemailbelongslist() {
    //this.emailslist.push({ 'text': 'Select', 'value': 0 });
    this.emailslist.push({ 'text': 'Dancer', 'value': 1 });
    this.emailslist.push({ 'text': 'Parents / Guardians', 'value': 2 });
    this.emailslist.push({ 'text': 'Other', 'value': 3 });

    this.emailsbelongs = this.emailslist;
  }

  geteventmediadata() {
    this.isLoading = true;
    this.selectListItem = [];
    this.selectListItem.push({ 'text': 'Select', 'value': 0 });
    this.DownloadMediaData.SeasonId = 0;

    this.subscription = this.downloadmediaService.Geteventmedia().subscribe(res => {
      if (res != null) {
        this.downloadmediadata = res;
        res.forEach(childObj => {
          if (childObj.selected == true) {
            this.selectedSeason = childObj.value;
          }
          this.selectListItem.push(childObj);
        });
        this.DownloadMediaData.SeasonId = this.selectedSeason;
        this.getEventsList();
      }
    }, err => {
      console.log('err in download event media component', err);
    });
  }

  getEventsList() {
    //this.isLoading = true;
    if (!(this.DownloadMediaData.SeasonId > 0)) {
      return;
    }
    // clear dancers, entries, email, date, month and year
    this.dancerslist = [];
    this.dancerslist.push({ 'Text': 'Select', 'Value': 0 });
    this.DownloadMediaData.DancerName = "0";

    this.entrieslist = [];
    this.entrieslist.push({ 'Text': 'Select', 'Value': 0 });
    this.DownloadMediaData.StudioEventEntryId = 0;

    this.month = 0;
    this.date = 0;
    this.year = 0;
    this.DownloadMediaData.Email = "";

    this.eventslist = [];
    this.eventslist.push({ 'Text': 'Select', 'Value': 0 });

    this.DownloadMediaData.EventId = 0;

    this.downloadmediaService.Getevents(this.DownloadMediaData.SeasonId).subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        res.Response.Data.forEach(childObj => {
          this.eventslist.push(childObj)
        })
        // clear the fields
        this.DownloadMediaData.DancerName = "0";
        this.DownloadMediaData.StudioEventEntryId = 0;
      }

    }, err => {
      console.log('err in download event media component', err);
      this.isLoading = false;
    });
  }

  getDancersList() {
    this.isLoading = true;
    if (!(this.DownloadMediaData.EventId > 0)) {
      return;
    }
    this.dancerslist = [];
    this.dancerslist.push({ 'Text': 'Select', 'Value': 0 });
    this.DownloadMediaData.DancerName = "0";

    this.downloadmediaService.Getdancers(this.DownloadMediaData.EventId).subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        res.Response.Data.forEach(childObj => {
          this.dancerslist.push(childObj)
        });

      }
    }, err => {
      console.log('err in download event media component', err);
      this.isLoading = false;
    });
  }

  getEntriesList() {
    this.isLoading = true;
    if (!(this.DownloadMediaData.EventId > 0)) {
      return;
    }

    this.entrieslist = [];
    this.entrieslist.push({ 'Text': 'Select', 'Value': 0 });
    this.DownloadMediaData.StudioEventEntryId = 0;

    this.downloadmediaService.Getentries(this.DownloadMediaData.EventId).subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        res.Response.Data.forEach(childObj => {
          this.entrieslist.push(childObj)
        })
      }
    }, err => {
      console.log('err in download event media component', err);
      this.isLoading = false;
    });
  }

  sendData() {
    this.isLoading = true;
    this.hour = "00";
    this.minute = "00";
    this.second = "00";
    this.birthdate = this.month + "/" + this.date + "/" + this.year + " " + this.hour + ':' + this.minute + ':' + this.second;
    this.DownloadMediaData.BirthDate = this.birthdate;

    this._Responsedata = this.downloadmediaService.Posteventmedia(this.DownloadMediaData).subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        if (res.body.success == true) {
          this.SuccessMessage = res.body.message;
        }
        else {
          if (res.body.isVerified == false) {
            this.isVerified = false;
          }
          this.ErrorMessage = res.body.message;
        }
      }
    }, err => {
      console.log('err in download event media component', err);
      this.isLoading = false;
    });
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  // When the user clicks on the resend button
  ResendVerification() {
    this.isLoading = true;
    this.Responsedata = this.downloadmediaService.ResendVerification(this.DownloadMediaData).subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        if (res.body.success == true) {
          this.SuccessMessage = res.body.message;
        }
        else {
          if (res.body.isVerified == false) {
            this.isVerified = false;
          }
          this.ErrorMessage = res.body.message;
        }
      }
    }, err => {
      console.log('err in download event media - Resend email', err);
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
