import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { doAnimate } from './shared/app.helpers';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';

declare var $: any;
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'RhythmWebApp';
  joinourteamlink: any;
  facebooklink: any;
  twitterlink: any;
  youtubelink: any;
  instagramlink: any;
  snapchatlink: any;
  tiktoklink: any;
  joinusemail: any;
  eewebformsubmit: any;

  constructor(private _httpService: Http, private router: Router) {

    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnInit() {

    $(document).ready(() => {
      $('body .menu a.a_grv').on('click', function (e) {
        $('.transition-overlay').removeClass("active");
        setTimeout(function () {
          $('.transition-overlay').toggleClass("active");
        }, 1000);
        setTimeout(function () {
          e.preventDefault();
          $('.transition-overlay').removeClass("active");
          $(".hamburger").toggleClass("open");
          $("body").toggleClass("overflow");
          $(".navigation-menu").removeClass("active");
          $(".navigation-menu .inner ul").css("transition-delay", "0s");
          $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
          $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");
        }, 2000);
      });

      // HAMBURGER MENU
      $('.hamburger-menu').on('click', function (e) {
        if ($(".navigation-menu").hasClass("active")) {
          $(".hamburger").toggleClass("open");
          $("body").toggleClass("overflow");
          $(".navigation-menu").removeClass("active");
          $(".navigation-menu .inner .menu").css("transition-delay", "0s");
          $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
          $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");
        } else {
          $(".navigation-menu").addClass('active');
          $(".hamburger").toggleClass("open");
          $("body").toggleClass("overflow");
          $(".navigation-menu.active .inner .menu").css("transition-delay", "0.45s");
          $(".navigation-menu.active .inner blockquote").css("transition-delay", "0.50s");
          $(".navigation-menu .bg-layers span").css("transition-delay", "0s");
        }
        $(this).toggleClass("active");
      });

      document.getElementById("hamburger-menu").addEventListener('click', function (e) {
        let audioPlayer = <HTMLVideoElement>document.getElementById("hamburger-hover");
        audioPlayer.play();
      });

      document.getElementById("hamburger-text").addEventListener('click', function (e) {
        let audioPlayer = <HTMLVideoElement>document.getElementById("hamburger-hover");
        audioPlayer.play();
      });

    });

    //this.joinourteamlink = "https://corehr.hrcloud.com/groove/#/jobs";
    this.joinourteamlink = "https://ats.rippling.com/onebeatdancebrands/jobs";
    this.facebooklink = "https://www.facebook.com/ReverbCompetition";
    this.twitterlink = "https://twitter.com/ReverbDanceComp";
    this.youtubelink = "https://www.youtube.com/channel/UCAc66s3hAuMiRsSeKGuT3ow";
    this.instagramlink = "https://www.instagram.com/reverbcompetition/";
    this.snapchatlink = "https://www.snapchat.com/add/Rhythmdancecomp";
    this.tiktoklink = "https://www.tiktok.com/@reverbdancecompetition";
  }

  //gotoRought(_url, isHref) {
  //  setTimeout(() => {
  //    if (isHref)
  //      window.open(_url, "_self");
  //    else
  //      this.router.navigate([_url]);
  //  }, 1500);
  //}

  gotoRought(_url, _isHref) {
    doAnimate();
    setTimeout(() => {
      if (_isHref)
        window.open(_url, "_self");
      else {
        this.router.navigate([_url]);
      }
    }, 500);
  }

  onNavigate(link) {
    if (link != "" && link != null) {
      window.open(link);
    }
    else {
      console.log('err in footer link');
    }
  }

  onJoinMailListClick(email) {
    //$event.stopPropagation();  
      doAnimate();
      setTimeout(() => {
        this.router.navigate(['/join-us', email]);
      }, 500);
   
  }
}


