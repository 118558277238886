import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { FacultyService } from '../../services/faculty.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FacultyComponent } from '../faculty/faculty.component';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-faculty-details',
  templateUrl: './faculty-details.component.html',
  styleUrls: ['./faculty-details.component.scss'],
  providers: [FacultyService],
})
export class FacultyDetailsComponent implements OnInit, OnDestroy {
  //route: any;
  subscription = new Subscription();

  constructor(private facultyService: FacultyService, private http: Http, private route: ActivatedRoute, private router: Router) { }
  facultydetail: any = [];
  facultyname: any;
  url: any;
  FacebookLink: any;
  InstagramLink: any;
  isLoading: boolean = false;

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.route.paramMap.subscribe(params => {
      this.facultyname = params.get('seoname');
    });

    this.getFaculty(this.facultyname);
  }

  getFaculty(seoname) {
    this.isLoading = true;
    this.subscription = this.facultyService.GetFacultyData(seoname).subscribe(data => {
      this.isLoading = false;
      if (data != null) {
        this.facultydetail.push(data.Response.Data.Faculty);
      }
    }, err => {
      console.log('err in faculty-detail component', err);
      this.isLoading = false;
    });
  }

  onNavigate(Link) {
    if (Link == null || Link == '') {
      return;
    }
    else {
      window.open(Link);
    }
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
