import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  forwardRef,
  Attribute
} from '@angular/core';
import {
  NG_VALIDATORS,
  AbstractControl,
  ValidatorFn,
  Validator,
  FormControl
} from '@angular/forms';
import { debug } from 'util';

@Directive({
  selector: '[sflNoSpaceValidate][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredNoSpaceValidator,
      multi: true
    }
  ]
})
export class RequiredNoSpaceValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateNoSpaceFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateNoSpaceFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      // tslint:disable-next-line:max-line-length
      if (c.value.trim() == 'Select' || c.value.trim() == 0 || c.value.trim() == '0') {
        return {
          appNoSpaceValidate: true
        };
      }
      else if (c.value.trim() !== '') {
        return null;
      }
      else {
        return {
          appNoSpaceValidate: true
        };
      }
    } else {
      return {
        appNoSpaceValidate: true
      };
    }
  };
}

@Directive({
  selector: '[sflEmailValidate][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }
  ]
})
export class EmailValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateEmailFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateEmailFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      // tslint:disable-next-line:max-line-length
      if (
        c.value.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return null;
      } else {
        return {
          appEmailValidate: true
        };
      }
    }
  };
}


