import { Component, OnInit, OnDestroy } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Subscription } from 'rxjs';
import { LiveStreamService } from '../../services/live-stream.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
  styleUrls: ['./live-stream.component.scss'],
  providers: [LiveStreamService],
})
export class LiveStreamComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private livestreamService: LiveStreamService, private http: Http, private sanitizer: DomSanitizer) { }
  LiveStreamdata: any = [];
  Livestreamcontent: any;
  isLoading: boolean = false;

  ngOnInit() {
    this.getLiveStreamData();
  }

  getLiveStreamData() {
    this.isLoading = true;
    this.subscription = this.livestreamService.GetLiveStream().subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        this.Livestreamcontent = res.Response.Data.Content;
        if (res.Response.Data.ImageGalleryList.length > 0) {
          res.Response.Data.ImageGalleryList.forEach(childObj => {
            childObj.ImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(childObj.ImageUrl);
            this.LiveStreamdata.push(childObj)
          })
        }
        //res.forEach(childObj => {
        //  childObj.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(childObj.imageUrl);
        //  this.LiveStreamdata.push(childObj)
        //})
      }
    }, err => {
      console.log('err in live stream component', err);
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
