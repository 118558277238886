import { Component, OnInit, OnDestroy } from '@angular/core';
import { Http, Response } from "@angular/http";
import { RulesandregulationsService } from '../../services/rulesandregulations.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rules-and-regulations',
  templateUrl: './rules-and-regulations.component.html',
  styleUrls: ['./rules-and-regulations.component.scss'],
  providers: [RulesandregulationsService],
})
export class RulesAndRegulationsComponent implements OnInit, OnDestroy {

  subscription = new Subscription();

  constructor(private rulesandregulationsService: RulesandregulationsService, private http: Http) { }

  Rulesdata: any;

  ngOnInit() {
    this.getrulesandregulationsData();
  }

  getrulesandregulationsData() {
    this.subscription = this.rulesandregulationsService.GetRulesData().subscribe(res => {
      if (res != null) {
        this.Rulesdata = res.Response.Data.Content;
      }
    }, err => {
      console.log('err in rulesandregulations component', err);
    });
  }

  onNavigate() {
    window.open(this.Rulesdata);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
