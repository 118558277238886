import { Component, OnInit, HostListener } from '@angular/core';
import { Http } from '@angular/http';
import { doAnimate } from 'src/app/shared/app.helpers';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { IImage } from 'ng-simple-slideshow';
import { HomeService } from '../../services/home.service';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.styl'],
  providers: [HomeService,
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: true } }
  ],
})
export class HomeComponent implements OnInit {
  subscription = new Subscription();
  imageUrls: (string | IImage)[] = [
    '/assets/images/home-header1.jpg',
    '/assets/images/home-header3.jpg',
    '/assets/images/slide003.jpg'
  ];
  height: string = '100%';
  minHeight: string;
  arrowSize: string = '30px';
  showArrows: boolean = false;
  disableSwiping: boolean = true;
  autoPlay: boolean = true;
  autoPlayInterval: number = 3333;
  stopAutoPlayOnSlide: boolean = false;
  debug: boolean = false;
  backgroundSize: string = 'cover';
  backgroundPosition: string = 'center center';
  backgroundRepeat: string = 'no-repeat';
  showDots: boolean = false;
  dotColor: string = '#FFF';
  showCaptions: boolean = true;
  captionColor: string = '#FFF';
  captionBackground: string = 'rgba(0, 0, 0, .35)';
  lazyLoad: boolean = false;
  hideOnNoSlides: boolean = false;
  width: string = '100%';
  fullscreen: boolean = false;
  enableZoom: boolean = false;
  enablePan: boolean = false;
  noLoop: boolean = false;
  Homedata: any;
  HomeWelcomedata: any;
  Conventiondata: any;
  Regionaldata: any;
  Nationaldata: any;
  Livestreamdata: any;
  ImageGalleryList: any = [];
  sponsorgallerylist: any = [];
  isLoading: boolean = false;
  isShowWelcomeMsg: boolean = false;
  customObj = {
    Abbreviation: "",
    Id: 0,
    ImageUrl: '',
    TitleLink: '',
  };
  constructor(private _httpService: Http, private router: Router, private homeService: HomeService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    $(document).ready(() => {
      // slider height
      $(".new_header").height(window.innerHeight + "px");
      $("video").prop('muted', true);
      // bottom to top button 
      $("#back2Top").click(function (event) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 2000);
        return false;
      });
    });

    this.getHomeData();
    this.getLivestreamData();
    this.getSponsorGalleryList();
  }

  getHomeData() {
    this.isLoading = true;
    this.subscription = this.homeService.GetHomeData().subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        this.Homedata = res.Response.Data.Content;
        this.HomeWelcomedata = res.Response.Data.HomeContent;
        this.Conventiondata = res.Response.Data.ConventionalContent;
        this.Regionaldata = res.Response.Data.RegionalContent;
        this.Nationaldata = res.Response.Data.NationalContent;
        if (this.HomeWelcomedata !== "") {
          this.isShowWelcomeMsg = true;
        } else {
          this.isShowWelcomeMsg = false;
        }

        res.Response.Data.ImageGalleryList.forEach(childObj => {
          //childObj.ImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(childObj.ImageUrl);
          this.ImageGalleryList.push(childObj.ImageUrl);
        })
      }
    }, err => {
      console.log('err in home component', err);
      this.isLoading = false;
    });
  }

  getLivestreamData() {
    this.isLoading = true;
    this.subscription = this.homeService.GetLivestreamData().subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        this.Livestreamdata = res.Response.Data.Content;
      }
    }, err => {
      console.log('err in home component', err);
      this.isLoading = false;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //console.log(event);
    console.log($(".new_header").height());
    $(".new_header").height(window.innerHeight + "px");
  }
  ngAfterViewInit() {
    $(".new_header").height(window.innerHeight + "px");
  }

  gotoRought(_url, _isHref) {
    doAnimate();
    setTimeout(() => {
      if (_isHref)
        window.open(_url, "_self");
      else {
        this.router.navigate([_url]);
      }
    }, 500);
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
    //document.body.scrollTop = 0; // For Safari
    //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getSponsorGalleryList() {
    this.homeService.getSponsorGalleryList().subscribe(SponsorGallery => {
      if (SponsorGallery != null) {        
        this.sponsorgallerylist = SponsorGallery.Response.Data;
        //this.customObj.Abbreviation = "GROOVE DANCE COMPETITION";
        //this.customObj.Id = 0;
        //this.customObj.ImageUrl = 'assets/images/GrooveOurFriends.png';
        //this.customObj.TitleLink = 'https://www.groovecompetition.com'
        //this.sponsorgallerylist.push(this.customObj);
      }
    }, err => {
      console.log('err in SponsorGallery - home component', err);
    });
  }

  getSponserLink(link) {
    if (link != "" && link != null) {
      window.open(link);
    }
    else {
      console.log('err in Sponsor gallery link');
    }
  }

}
