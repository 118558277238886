import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  email: any;

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.route.paramMap.subscribe(params => {      
      this.email = params.get('email');
    });
  }

}
