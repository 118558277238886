import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js';

declare var $: any;
@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {



    const options = {
      strings: ["Please wait", "Still loading", "Almost done"],
      typeSpeed: 100,
      backSpeed: 100,
      showCursor: true,
      cursorChar: '|',
      loop: true
    };

    const typed = new Typed('.typed-element', options);


    //$(document).ready(() => {
    //  // TYPEWRITER
    //  if ($('#typewriter_loader #typewriter-text-custom').length > 0) {
    //  }
    //  else {
    //    $("#typewriter_loader").typewriter_custom({
    //      prefix: "",
    //      text: ["Please wait", "Still loading", "Almost done"],
    //      typeDelay: 100,
    //      waitingTime: 1500,
    //      blinkSpeed: 800
    //    });
    //  }
    //});

  }

}
