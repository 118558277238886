import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImportantService } from '../../services/important.service';
import { Http } from '@angular/http';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-important',
  templateUrl: './important.component.html',
  styleUrls: ['./important.component.scss']
})
export class ImportantComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  Importantdata: any;
  isLoading: boolean = false;

  constructor(private imortantsService: ImportantService, private http: Http, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getImportantData();
  }

  getImportantData() {
    this.isLoading = true;
    this.subscription = this.imortantsService.GetImportantsData().subscribe(res => {     
      if (res != null) {
        if (res.Response.Success == true) {
          this.Importantdata = res.Response.Data.Important;
          this.isLoading = false;
        }
        else {
          this.Importantdata = {
            Title: "Important Notes",
            Content: "No notes are there!"
          }
          this.isLoading = false;
        }
      }
    }, err => {
        console.log('err in important component', err);
        this.isLoading = false;
    });
  }

  transform(style) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
