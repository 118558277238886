import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.scss']
})
export class HiringComponent implements OnInit {
  joinourteamlink: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    //this.joinourteamlink = "https://corehr.hrcloud.com/groove/#/jobs";
    this.joinourteamlink = "https://ats.rippling.com/onebeatdancebrands/jobs";
  }

  onNavigate(link) {
    if (link != "" && link != null) {
      window.open(link);
    }
    else {
      console.log('err in footer link');
    }
  }

}
