import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/url.constants';
import { TourDate } from '../models/TourDate';

@Injectable({
  providedIn: 'root'
})
export class TourDateService {

  constructor(private http: HttpClient) { }

  ///** GET tour-date data from the server */ 
  GetSeasonEventlist(data: TourDate): Observable<any> {
    const httpBody = new HttpParams()
      .set('seasonId', data.seasonId.toString())
      .set('eventType', data.eventType)
      .set('stateId', data.stateId.toString())
      .set('month', data.month.toString())
      .set('RequestName', '4');


    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.TOURDATE_SERVER_URL, httpBody, options);
  }

  ///** GET seasons from the server */
  GetSeasonlist(): Observable<any> {
    return this.http.get(API_URL.TOURDATE_URL + "/GetSeasonlist");
  }

  ///** GET states from the server */
  GetStatelist(): Observable<any> {
    return this.http.get(API_URL.TOURDATE_URL + "/GetStatelist");
  }

  /*GET event detail from the server */
  GetEventDetail(eventseoname): Observable<any> {
    const httpBody = new HttpParams()
      .set('SeoName', eventseoname);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.TOURDETAIL_SERVER_URL, httpBody, options);
  }

  GetNationalFinalsList(): Observable<any> {
    const httpBody = new HttpParams();

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.NATIONALIMAGES_SERVER_URL, httpBody, options);
    //return this.http.get(API_URL.TOURDATE_URL + "/GetNationalFinalsList");
  }
}
