import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { FacultyService } from '../../services/faculty.service';
import { Subscription } from 'rxjs';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { doAnimate } from 'src/app/shared/app.helpers';

declare var $: any;

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.scss'],
  providers: [FacultyService],
})
export class FacultyComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private facultyService: FacultyService, private http: Http, private router: Router) { }
  Facultydata: any;
  url: any;
  index: any = 0;
  isLoading: boolean = false;

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.getFacultyDetails();
  }

  getFacultyDetails() {
    this.isLoading = true;
    this.subscription = this.facultyService.GetFaculty().subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        this.Facultydata = res.Response.Data.FacultyList;
      }
    }, err => {
      console.log('err in faculty component', err);
      this.isLoading = false;
    });
  }

  onNavigate(type, data) {

    if (this.index > 0) {
      type = "Default";
    }

    if (data == null || data == '') {
      return;
    }

    if (type == "Detail") {
      doAnimate();
      setTimeout(() => {
        this.router.navigate(['/faculty-detail', data]);
      }, 500);

      //this.router.navigate(['/faculty-detail', data]);
    }
    else if (type == "facebook") {
      this.index = this.index + 1;
      window.open(data);
    }
    else if (type == "instagram") {
      this.index = this.index + 1;
      window.open(data);
    }
    else if (type == "website") {
      this.index = this.index + 1;
      window.open(data);
    }
    else if (type == "Default") {
      this.index = 0;
    }
    else {
      console.log('err in faculty component - detail & social links');
    }
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}



