import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { ExecutiveteamService } from '../../services/executiveteam.service';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-executive-team',
  templateUrl: './executive-team.component.html',
  styleUrls: ['./executive-team.component.scss'],
  providers: [ExecutiveteamService],
})
export class ExecutiveTeamComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private executiveteamService: ExecutiveteamService, private http: Http) { }
  Executiveteamdata: any;
  isLoading: boolean = false;

  ngOnInit() {
    this.getExecutiveteamData();
  }

  getExecutiveteamData() {
    this.isLoading = true;
    this.subscription = this.executiveteamService.GetExecutiveteam().subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        this.Executiveteamdata = res.Response.Data.FacultyList;
      }
    }, err => {
      console.log('err in executive team component', err);
      this.isLoading = false;
    });
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
