import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { BlogService } from '../../services/blog.service';
import { Subscription } from 'rxjs';
import { Routes, RouterModule, Router, NavigationEnd} from '@angular/router';
import { doAnimate } from 'src/app/shared/app.helpers';

declare var $: any;

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  providers: [BlogService],
})
export class BlogComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  constructor(private blogService: BlogService, private http: Http, private router: Router) { }
  Blogdata: any;
  url: any;
  index: any = 0;
  isLoading: boolean = false;
  page: number = 1;

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.getBlogData();
  }

  getBlogData() {
    this.isLoading = true;
    this.subscription = this.blogService.GetBlog().subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        this.Blogdata = res.Response.Data.NewsBlogList;
      }
    }, err => {
      console.log('err in blog component', err);
      this.isLoading = false;
    });
  }

  onNavigate(type, data) {    
    if (this.index > 0) {
      type = "Default";
    }

    if (data == null || data == '') {
      return;
    }

    if (type == "NewsDetail") {
      doAnimate();
      setTimeout(() => {
        this.router.navigate(['/news-detail', data]);
      }, 500);
    }

    else if (type == "Default") {
      this.index = 0;
    }
    else {
      console.log('err in news component - detail');
    }
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }

  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
