import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DownloadMediaService } from '../../services/download-media.service';

@Component({
  selector: 'app-dancer-verification',
  templateUrl: './dancer-verification.component.html',
  styleUrls: ['./dancer-verification.component.scss']
})
export class DancerVerificationComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private route: ActivatedRoute, private downloadmediaService: DownloadMediaService) { }

  VerificationId: any;
  SuccessMessage: any;
  ErrorMessage: any;
  isLoading: boolean = false;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.VerificationId = params.get('VerificationId');
    });

    this.VerifyDancerRegistration(this.VerificationId);
  }

  VerifyDancerRegistration(verificationId) {
    this.isLoading = true;
    this.subscription = this.downloadmediaService.VerifyDancerRegistration(verificationId).subscribe(res => {
      this.isLoading = false;
      if (res != null) {
        console.log(res.Response);
        if (res.Response.Success == true) {
          this.SuccessMessage = res.Response.Data;
        }
        else {
          this.ErrorMessage = res.Response.Data;
        }
      }
    }, err => {
      console.log('err in DANCER VERIFICATION component', err);
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
